@import '../../styles/globals';
@import '../../styles/vars';

.inscription {
  min-height: 100vh;
  margin: 0 auto;
  width: $maxWidth;
  color: white;
  text-align: start;

  &-apiReturn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem
  }

  &-text {
    font-size: 1.1rem;
  }

  &-form {
    display: grid;
    gap: 0 1em;
    grid-template-columns: repeat(2, calc(50% - 1rem));
    
    &--input {
      display: flex;
      flex-direction: column;

      
      & > input {
        border: none;
        padding: 1rem;
        border-radius: 5px;
      }

      & > label {
        margin-bottom: 0.5rem;
      }
      
      & > select {
        border: none;
        padding: 1rem;
        border-radius: 5px;
        line-height: 20px;

        & > option {
          font-size: 0.9rem;
        }
      }
      
      & > textarea {
        max-width: 100%;
        padding: 1rem;
        resize: vertical;
        font-family: Arial, Helvetica, sans-serif;
      }
      
      &_event, &_message, &_email {
        grid-column: 1 / 3;
      }
    }

    &--error {
      width: 100%;
      grid-column:  1/ 3;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 40em) {
  .inscription {
    &-form {
      display: flex;
      flex-direction: column;
      gap: 0 2em;
    }
  }
}