@import '../../../../styles/vars';

.spinner {
  margin: 2rem auto;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-top: 3px solid white;
  border-radius: 50%;
  animation: rotateSpinner 1.3s linear infinite forwards;
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(220deg);
  }
  100% {
    transform: rotate(360deg);
  }
}