@import '../../styles/globals';
@import '../../styles/vars';

.values-mobile {
  max-width: $maxWidth;
  margin-inline: auto;
  text-align: start;
  display: flex;
  flex-direction: column;

  &--title {
    font-size: clamp(2.3rem, 3vw + 0.5rem, 3rem);
    text-transform: uppercase;
    color: #ffffff40;
    line-height: 1.2;
    display: inline-block;

    &_first {
      color: white;
    }
  }
}