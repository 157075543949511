@import '../../../styles/vars';

.presentation {
  clip-path: url(#presentation-img-clip);
  background-image: url('../../../../public/imgs/pages/large/formacoach-about-large.jpg');
  box-shadow: inset 0 30rem 30rem #00000070;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: -7rem;
  padding: 10rem 0 0;
  // Compensate top position
  margin-bottom: -15rem;

  &-content {
    max-width: $maxWidth;
    margin-inline: auto;

    &--title {
      margin: 3rem 2rem 1rem;
    }

    & > ul {
      padding: 0;
      margin: 0 1rem;
    }

    &--list {
      margin: 0 2rem 2rem;

      &_item {
        margin-bottom: 1rem;
        text-align: center;
        list-style-type: none;
      }
    }
  }
}

@media (max-width: 40em) {
  .presentation {
    margin-bottom: -6rem;
    height: 600px;

    & ul,
    li {
      height: auto;
    }

    &-content {
      &--title {
        margin: 2rem 1rem 1rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .presentation {
    background-image: url('../../../../public/imgs/pages/small/formacoach-homepage-small.jpg');
  }
}
