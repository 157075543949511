@import '../../styles/vars';

.formacoach {
  height: 100%;
  min-height: 100vh;
  text-align: center;
  // Fallback background is blue dark
  background: $blueDark;
  background-image: linear-gradient(236deg, $blueLight 0%, $blueDark 50%, $pink 100%);
  background-size: 200%;
  animation: animate-gradient 15s infinite alternate;
  
  // Used to keep footer at the bottom
  &-container {
    min-height: 85vh;
  }
}


@keyframes animate-gradient {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

@media (max-width: 70em) {  
.formacoach {
    width: 100vw;
    max-width: 100%;
  }
}