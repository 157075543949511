@import '../../../styles/vars';

.clientsLogos {
  position: relative;
  z-index: 100;
  background-color: #ffffff;
  padding: 1rem 0 5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  &-title {
    color: $pink;
    margin: 0.5rem 0 1rem;
  }

  &-list {
    max-width: $maxWidth;

    &--big,
    &--small {
      display: inline-flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    &--big {
      padding: 0 5rem;

      &_item > img {
        max-height: 4rem;
        object-fit: cover;
        max-width: 14rem;
      }
    }

    &--small {
      display: inline-flex;
      margin-bottom: 1rem;

      &_item > img {
        max-height: 5rem;
        object-fit: cover;
        max-width: 14rem;
      }
    }
  }

  // Patch for background
  &-rectangle {
    z-index: 95;
    width: 100%;
    height: 7rem;
    position: absolute;
    bottom: 0;
    transform: translateY(3rem);
    background-color: white;
  }
}

.background {
  z-index: 90;
  position: relative;
  transform: rotateX(180deg) translateY(-5rem);
  // transform: rotateX(180deg) translateY(-4rem);
}

@media (max-width: 40em) {
  .clientsLogos {
    &-list {
      gap: 0.7rem;

      &--item > img {
        height: 2.2rem;
      }
    }
  }
}
