@import '../../styles/globals';
@import '../../styles/vars';

.atelierDetails {
  max-width: $maxWidth;
  margin-inline: auto;
  text-align: start;
  color: white;

  &-details {
    display: flex;
    margin: 1rem 0;
    gap: 1rem;

    // LEFT COL
    &--left {
      min-width: calc(50% - 0.5rem);

      &_prerequisites,
      &_desc &_objectives,
      &_modalities {
        display: flex;
        gap: 0.5rem;
      }

      &_desc {
        flex-direction: column;
        margin: 0;
        padding-bottom: 2rem;
        font-size: 1.2rem;
      }

      &_dates {
        display: flex;
        align-items: baseline;
        gap: 0.2rem;
      }

      &_duration {
        margin-bottom: 2rem;
      }

      &_price {
        font-size: clamp(2rem, 4vh, 3rem);
      }

      &_objectives {
        flex-direction: column;
      }
    }

    // RIGHT COL
    &--right {
      min-width: calc(50% - 0.5rem);
      display: flex;
      flex-direction: column;

      &_img {
        & > img {
          max-width: 100%;
          padding-bottom: 1rem;
        }
      }

      &_btn {
        width: 100%;
      }
    }
  }
}

.eventsList {
  margin: 2rem 0;
}

@media (max-width: 40em) {
  .atelierDetails {
    &-details {
      flex-direction: column-reverse;

      &--left {
        &_objectives {
          flex-direction: column;
          margin: 0;
          gap: 0;
        }
      }
    }
  }
}
