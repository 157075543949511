@import '../../styles/globals';
@import '../../styles/vars';

.contacts {
  min-height: 70vh;
  margin: 0 auto;
  width: $maxWidth;
  color: white;
  text-align: start;

  &-apiReturn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem;
  }

  &-text {
    font-size: 1.1rem;
  }

  &-form {
    display: grid;
    gap: 0 2em;
    grid-template-columns: repeat(2, 1fr);

    &--input {
      display: flex;
      flex-direction: column;

      & > input {
        border: none;
        padding: 1rem;
        border-radius: 5px;
      }

      & > label {
        margin-bottom: 0.5rem;
      }

      & > select {
        border: none;
        padding: 1rem;
        border-radius: 5px;
        line-height: 20px;

        & > option {
          font-size: 0.9rem;
        }
      }

      & > textarea {
        max-width: 100%;
        padding: 1rem;
        resize: vertical;
        font-family: Arial, Helvetica, sans-serif;
      }

      &_message {
        grid-column: 1 / 3;
      }
    }
  }
}

@media (max-width: 65em) {
  .contacts {
    margin-bottom: 2rem;

    &-form {
      display: flex;
      flex-direction: column;
      gap: 0 2em;

      &--btn {
        align-self: center;
      }
    }
  }
}
