@import '../../styles/_vars';

.footer {
  text-align: left;
  color: white;
  position: relative;
  bottom: 0;
  margin-top: 2rem;

  &-qualiopi {
    width: 30%;
    cursor: pointer;
  }

  &-content {
    z-index: 90;
    position: absolute;
    padding: 1rem 1.5rem 0.7rem;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;

    &--left {
      display: flex;
      // flex-direction: row-reverse;
      align-items: flex-end;
      gap: 1rem;

      & ul {
        padding: 0;

        & > li {
          margin-left: 0.5rem;
        }
      }

      &_contacts {
        &-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          text-align: start;
          font-size: 0.9rem;
          line-height: 1.2rem;
        }
      }
    }

    &--right {
      display: flex;
      align-items: baseline;
      gap: 1rem;
      font-size: 1rem;
      position: relative;
      top: 70px;
    }
  }

  &-background {
    position: relative;
    bottom: 0;
   width: 100%;
    transform: rotateX(180deg);

    &--rectangle {
      width: 100%;
      height: 5rem;
      background-color: white;
      position: absolute;
      top: 0;
    }
  }
}

@media (min-width: 1920px) {
  .footer {
    &-content {
      &--left {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 72em) {
  .footer {
    &-content {
      &--left {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }

    &-background {
      &--wave {
        display: none;
      }

      &--rectangle {
        position: inherit;
        height: 8rem;
      }
    }
  }
}

@media (max-width: 40em) {
  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-qualiopi {
      width: 80%;
      margin-block: 20px;
    }

    &-content {
      width: calc(100% - 1rem);
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.5rem 0.7rem;

      &--right {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
        position: relative;
        top: 0px;
      }
    }

    &-background {
      &--wave {
        display: none;
      }

      &--rectangle {
        position: inherit;
        height: 11rem;
      }
    }
  }
}
