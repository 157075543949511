.ateliers-list {
  & > a {
    &:visited {
      color: rgb(221, 221, 221);
    }
  }

  &--item {
    color: white;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    &:hover {
      background: #FFFFFF20;
      border-radius: 100rem;
    }
    
    &_title {
      grid-column: 1/3;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      font-size: 1.2rem;
      font-weight: 700;
      flex-grow: 2;
    }
  
    &_details {
      grid-column: 3;
      text-align: start;
      display: flex;
      gap: 0.5rem;
      align-items: flex;
      flex-grow: 1;

      &-duration {}

      &-certification {
        font-weight: 100;
      }
    }
  }
}


@media (max-width: 40em) {  
.ateliers-list {
  padding: 0.5rem 0;
    &--item {
      padding: 0.5rem 0;

      &_title {
        grid-column: 1/4;
      }

      &_details {
        display: none;
      }
    }
  }
}
