@import '../../../styles/vars';

.chart {
  overflow: hidden;
  margin-bottom: -10rem;
  
  &-title, &-desc {
    max-width: 80%;
    max-width: $maxWidth;
    margin-inline: auto;
  }

  &-content {
    max-width: $maxWidth;
    margin-inline: auto;
    overflow: hidden;
    // margin-top: -2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    //////////////////////////////////////////////////////////////////////
    // ARROWS
    //////////////////////////////////////////////////////////////////////
    &--prev, &--next {
      z-index: 99999;
      padding: 1rem;
      width: clamp(1rem, 2vw + 1rem, 3rem);
      height: clamp(1rem, 2vw + 1rem, 3rem);
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-top-left-radius: 3px;
      cursor: pointer;
      position: relative;      
      height: 100%;
    }
    
    &--prev {
      transform: rotate(-45deg);
      left: 1rem;
    }
    
    &--next {
      right: 5rem;
      transform: rotate(135deg);
    }

    //////////////////////////////////////////////////////////////////////
    // SLIDER
    //////////////////////////////////////////////////////////////////////
    &--slider {
      white-space: nowrap;
      width: 100%;
      margin: -2rem 0;
      
      &_item {
        display: inline-flex;
        white-space: normal;
        height: 15rem;
        width: 100%;
        
        &-details {
          transition: 300ms ease-in-out;
          padding: 0 5rem 0 3rem;
          display: flex;
          text-align: center;
          align-items: center;
          width: 100%;

          &--title {
            width: 85%;
            margin-inline: auto;
            
            & > h3 {
              text-align: center;
            }
          }

        }
      }
    }
  }

    
  &-index {
    // POSITION
    z-index: 10;
    position: relative;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);

    // STYLE
    border: 3px solid white;
    border-radius: 50%;
    padding: 1rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;    
    // Supposed to work but doesn't...
    // place-content: center;
    
    // BACKGROUND
    background: $blueDark;
    background-image: linear-gradient(236deg, $blueDark 0%, $pink 100%);
    background-size: 800%;
    animation: animate-gradient 15s infinite alternate;
  }
  
  &-borderContainer {
    position: relative;
    inset: 0;
    width: 100vw;
    height: auto;
    
    &--border {
      overflow-x: hidden;
      position: relative;
      top: -6.5rem;
      left: -25%;
      width: 150%;
      height: 300px;
      border-top: 3px solid white;
      border-radius: 50%;
      margin-bottom: -10rem;
    }
  }

}

@media (min-width: 40em) {
  .chart {
    &-content {
      margin-bottom: 4rem;
    }

    &-borderContainer {
      margin-top: 4rem;

      &--border {
        margin-top: 4rem;
      }
    }
  }
}


@media (max-width: 40em) {
  .chart {
    &-content {
      
      &--prev, &--next {
        width: 0.1rem;
        height: 0.1rem;
      }

      &--next {
        right: 3rem;
      }
      
      &--slider {
        width: 100%;
        margin: -2rem 0 2rem -2rem;    
        &_item {
          &-details {
            padding: 0 2rem 0;
            
            & h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }

    &-borderContainer {
      // overflow: hidden;
      top: -1rem;
    }

    &-index {
      top: -5rem;
    }
  }
}


@media (max-width: 25em) {
  .chart {
    &-content {
      margin-bottom: 2rem;
      &--prev {
        left: 0;
        transform: rotate(-45deg) scale(0.5);
      }
      
      &--next {
        right: 2rem;
        transform: rotate(135deg) scale(0.5);
      }
    }
  }
}


@keyframes animate-gradient {
  0% {
    background-position: top 50% left -50%;
  }
  100% {
    background-position: top 70% right -200%;
  }
}