@import '../../styles/globals';
@import '../../styles/vars';

.eventDetails {
  max-width: $maxWidth;
  margin-inline: auto;
  text-align: start;
  color: white;

  &-details {
    display: flex;
    margin: 1rem 0;
    gap: 1rem;
    color: white;

    // LEFT COL
    &--left {
      min-width: calc(50% - 0.5rem);

      &_desc {
        margin: 0;
        padding-bottom: 2rem;
        font-size: 1.2rem;
      }

      &_dates,
      &_modalities,
      &_prerequisites,
      &_handicap {
        display: flex;
      }

      &_dates {
        align-items: baseline;
        gap: 0.2rem;

        &-date {
          display: flex;
          gap: 0.4rem;
          align-items: baseline;
        }
      }

      &_prerequisites {
        align-items: baseline;
        gap: 0.5rem;
      }

      &_handicap {
        align-items: center;
        gap: 0.5rem;

        &-icon {
          font-size: 2rem;
        }
      }

      &_infos {
        padding: 1rem 0 0;

        &__price {
          font-size: 3rem;
        }

        &__duration {
          display: flex;
          align-items: baseline;
          gap: 0.3rem;
        }

        &__certification {
          display: flex;

          &-picto {
            max-width: 2rem;
            color: white;
            margin: 0 0.5rem;
          }
        }
      }
    }

    // RIGHT COL
    &--right {
      min-width: calc(50% - 0.5rem);
      display: flex;
      flex-direction: column;

      &_img {
        & > img {
          max-width: 100%;
          padding-bottom: 1rem;
        }
      }

      &_btn {
        display: flex;
        gap: 1rem;
        align-items: center;
        width: 100%;
      }

      &_duration,
      &_certification,
      &_modalities {
        display: flex;
      }

      &_objectives > p {
        color: white;
      }

      &_satisfaction {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
      }
    }
  }
}

.eventsList {
  margin: 2rem 0;
}

@media (max-width: 70em) {
  .eventDetails {
    &-details {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 40em) {
  .eventDetails {
    &-details {
      &--left {
        &_dates {
          flex-direction: column;
        }
        &_modalities {
          flex-direction: column;
          gap: 0;
        }
      }

      &--right {
        &_btn {
          flex-direction: column;
        }

        &_satisfaction {
          flex-direction: column;
        }
      }
    }
  }
}
