@import '../../styles/vars';

.homepage {
  color: white;
  overflow: hidden;
  margin-top: 2rem;
  //
  margin-bottom: -7rem;

  &-section {
    // margin-bottom: 5rem;
  }
}

@media (max-width: 40em) {
  .homepage {
    width: 100%;
  }
}
