@import './vars';

///////////////////////////////////////////////////////////////
// TYPE
///////////////////////////////////////////////////////////////
.title {
  &-1 {
    text-align: start;
    color: white;
    // Will adapt between 3 and 4 rems depending on screen with. Ideal size in middle
    font-size: $big;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: $black;
  }

  &-2 {
    color: white;
    font-size: $big;
    line-height: 1;
    letter-spacing: 0.2rem;
    font-weight: $black;
  }

  &-3 {
    font-size: $medium;
    line-height: 1;
    font-weight: $xbold;
  }

  &-4 {
    font-size: clamp(1rem, 1.3rem, 1.5rem);
    line-height: 1;
    // letter-spacing: 0.1rem;
  }

  &-5 {
    font-size: clamp(1rem, 2vh + 0.1rem, 3rem);
    line-height: 1;
    // letter-spacing: 0.1rem;
  }

  &-chart {
    color: white;
    font-size: clamp(1.2rem, 4vw + 0.4rem, 3.5rem);
    line-height: 1;
    letter-spacing: 0.1rem;
    text-align: center;
    font-weight: $black;
  }
}

.text {
  &-main {
    text-align: start;
    color: white;
    line-height: clamp(1, 5vh + 0.5rem, 1.2);
    font-size: clamp(0.9rem, 2vw + 1rem, 1.1rem);
    font-weight: $regular;
  }

  &-header {
    text-align: start;
    color: white;
    font-size: clamp(1rem, 2vw + 1rem, 1.2rem);
    line-height: clamp(1, 5vh + 0.5rem, 1.2);
  }

  &-legend {
    text-align: start;
    color: white;
    font-size: clamp(0.9rem, 2.5vw + 0.9rem, 1rem);
    line-height: clamp(1, 5vh + 0.5rem, 1.2);
  }
}

.italic {
  font-style: italic;
}

.errors {
  font-size: 0.9rem;
  font-weight: 300;
}

// Font weights
.black {
  font-weight: $black;
}

.xbold {
  font-weight: $xbold;
}

.bold {
  font-weight: $bold;
}

.sbold {
  font-weight: $sbold;
}

.regular {
  font-weight: $regular;
}

.thin {
  font-weight: $thin;
}

.light {
  font-weight: $light;
}

.xlight {
  font-weight: $xlight;
}

///////////////////////////////////////////////////////////////
// COMPONENTS
///////////////////////////////////////////////////////////////
.actionButton,
.navButton {
  border: none;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  // width: min(100%, 10em);
  width: fit-content;
  padding: 0.5rem 1rem;
  background-color: $pink;
  border-radius: 50px;
  transition: 50ms ease-in-out;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.42);

  &:hover {
    background-color: $blueLight;
  }
}

.page {
  padding-top: 7rem;
}

///////////////////////////////////////////////////////////////
// RESPONSIVE
///////////////////////////////////////////////////////////////
@media (max-width: 40em) {
  .page {
    padding-top: 5rem;
  }

  .title {
    &-1,
    &-2 {
      letter-spacing: 0.1rem;
    }
  }
}
