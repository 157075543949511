@import '../../styles/vars';

.coaching {
  max-width: $maxWidth;
  margin-inline: auto;
  text-align: start;
  color: white;

  &-cardslist {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
  }

  &-itemslist {
    padding: 1rem 0;
  }
}

.button {
  margin-top: 2rem;
}

@media (max-width: 40em) {
  .coaching {
    &-list {
    }
  }
}
