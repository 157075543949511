@import '../../../styles/_vars';

.formation-listItem {
  color: white;
  text-decoration: none;
  max-width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  width: $maxWidth;

  &:visited {
    color: rgb(221, 221, 221);
  }

  &:hover {
    background: #ffffff20;
    border-radius: 100rem;
  }

  &--title {
    grid-column: 1/3;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    font-size: clamp(0.9rem, 2vw + 0.5rem, 1.2rem);
    font-weight: 700;
    flex-grow: 2;
  }

  &--details {
    grid-column: 3;
    text-align: start;
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    flex-grow: 1;
    margin-left: 1rem;

    &_certification {
      font-weight: 200;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &-picto {
        max-width: 2rem;
        transform: translateY(0.3rem);
      }
    }
  }
}

@media (max-width: 70em) {
  .formation-listItem {
    padding: 0.5rem 1rem;

    &--title {
      grid-column: 1/4;
      white-space: pre-line;
    }

    &--details {
      display: none;
    }
  }
}

@media (max-width: 50em) {
  .formation-listItem {
    height: auto;
    margin-bottom: 0.5rem;

    &:hover {
      background: none;
      border-radius: 0;
    }
  }
}
