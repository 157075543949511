@import '../../../styles/globals';
@import '../../../styles/vars';

.formation-card {
  text-align: start;
  color: white;
  padding: 0.5rem 1rem 1rem;
  width: clamp(17rem, 48% - 2rem, 35rem);
  height: fit-content;
  background: #ffffff50;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 100ms ease-out;

  &:hover {
    box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(-0.2rem);

    img {
      transform: scale(1.07);
    }
  }

  &--header {
    width: 100%;

    &_title {
      // background-color: red;
      height: 3.5rem;
    }

    &_title,
    & > a {
      text-decoration: none;
      color: white;
      font-size: clamp(1.2rem, 1vw + 0.5rem, 1.4rem);
      line-height: 1.7rem;
      font-weight: $bold;
    }
  }

  &--img {
    margin: 0.5rem auto;
    max-width: fit-content;
    max-height: 15rem;
    overflow: hidden;

    & > img {
      width: 100%;
      transition: 200ms ease-in-out;
    }
  }

  &--program {
    overflow-y: hidden;
    margin: 0 1rem 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  &--arrow {
    margin-inline: auto;
    font-size: 3rem;
    width: 100%;
  }

  &--details {
    display: flex;
    align-items: center;

    &_infos {
      flex-grow: 1;

      &__duration {
        display: flex;
        align-items: center;
        font-weight: 900;
        gap: 0.2rem;
        padding-right: 0.3rem;
      }

      &__location {
        padding-right: 0.3rem;
        font-weight: 500;
      }

      &__certification {
        font-weight: 100;
        margin-right: 0.5rem;
      }
    }
  }
}

.shadowbox {
  margin: -10px 0 1rem;
  max-width: 100%;
  text-align: center;
  background: #ffffff00;
  box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.2) inset;
  -webkit-box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.2) inset;

  &:hover {
    cursor: pointer;
    box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.4) inset;
    -webkit-box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.4) inset;
    -moz-box-shadow: 0 -6.5rem 3rem -7rem rgba(0, 0, 0, 0.4) inset;
  }
}

@media (max-width: 60em) {
  .formation-card {
    &--header {
      &_title {
        height: auto;
      }
    }
    &--details_infos {
      &__certification {
        display: none;
      }
    }
  }
}

@media (max-width: 45em) {
  .formation-card {
    width: calc(100% - 2rem);
    max-width: 30rem;

    &:hover {
      box-shadow: none;
      transform: none;

      img {
        transform: none;
      }
    }

    &--details {
      &_infos {
        font-size: 1rem;
        &__certification {
          display: initial;
        }
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 25em) {
  .formation-card {
    &--details {
      &_infos {
        &__certification {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 25em) {
  .formation-card {
    &--details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      &_infos {
        &__certification {
          display: initial;
        }
      }
    }
  }
}
