@import '../../styles/globals';
@import '../../styles/vars';

.formateurs {
  text-align: start;
  width: $maxWidth;
  margin-inline: auto;

  &-categories {
    color: white;

    &--title {
      margin: 1rem 0;
    }

    &--list {
      margin: 1rem 0 2rem;
      display: flex;
      gap: 1rem;

      &_item {
        font-size: clamp(0.9rem, 1vw + 0.4rem, 1.1rem);
        background-color: #ffffff50;
        border-radius: 500px;
        padding: 0.5rem 1rem;
        color: white;
        transition: 50ms ease-in;
        font-weight: $regular;
        width: 6rem;
        text-align: center;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          font-weight: $bold;
        }
      }
    }
  }

  &-formations {
    &--list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;

      &_title {
        margin-top: 1rem;
        width: 100%;
        text-align: start;
        color: white;
      }
    }

    &--title {
      color: white;
      margin: 2.5rem 0 1rem;
    }
  }

  &-supervision {
    margin-top: 2rem;

    &--text {
      margin-bottom: 2rem;
    }
  }

  &-subtitle {
    color: white;
    padding-top: 0.5rem;
  }

  &-cardslist {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  // padding: 1rem 0;
  &-itemslist {
    &--title {
      color: white;
      margin: 1rem 0;
    }
  }
}

.selected {
  background-color: $pink;
}

.button {
  margin-top: 2rem;
}

@media (max-width: 40em) {
  .formateurs {
    &-categories {
      &--list {
        flex-wrap: wrap;
        gap: 1rem;
      }
    }

    &-cardslist {
      flex-direction: column;
    }

    &-itemslist {
      padding: 1rem 0;
    }
  }
}
