@import '../../styles/vars';
@import '../../styles/globals';


.legals {
  max-width: $maxWidth;
  margin-inline: auto;
  margin-bottom: 2rem;
  color: white;
  text-align: start;

  &-link {
    color: white;
    font-weight: bold;
  }

  &-subtitle {
    margin: 2rem 0 1rem;
  }
}