@import '../../../../styles/vars';

a {
  text-decoration: none;
}

.offerCard {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: clamp(0.5rem, 0.7rem, 1.2rem);
  // width: 400px;
  padding: 2rem 1rem 1rem;
  align-items: center;
  height: 6rem;

  &-picto {
    color: white;

    & > img {
      height: 3rem;
      transition: 100ms ease-in-out;
    }
  }

  &-title {
    font-size: clamp(1rem, 2vw + 0.1rem, 1.7rem);
  }
  
  &:hover &-picto > img {
    transform: scale(1.2);
  }
}

.border {
  background-color: #FFFFFF20;
  border: 3px solid white;
  border-radius: 10px;
  
    &:hover {
      box-shadow: inset 0.1em 0.1em 0.7em #00000060;
    }
}

@media (max-width: 70em) {
  .offerCard {
    padding: 1.5rem 1rem 1rem;
  }
}

@media (max-width: 55em) {
  .offerCard {
    padding: 1rem;
  }
}

@media (max-width: 45em) {
  .offerCard {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 3rem;
    padding: 1rem 2rem 0.6rem;

    &:hover &-picto > img {
      pointer-events: none;
      transform: scale(1);
    }

    &-title {
      margin: 0 0 0.4rem 1rem;
      font-size: clamp(1rem, 2vw + 0.5rem, 3rem);
    }
  }
}