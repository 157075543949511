@import '../../styles/_vars';

.header {
  opacity: 0;
  position: fixed;
  z-index: 99;
  top: -1.5em;
  width: 100vw;
  max-width: 100%;
  background-color: white;
  clip-path: url(#header-clip);
  
  &-content {
    padding: 1.2em 1rem 0 0;
    display: flex;
    align-items: center;

    &--logo {
      width: fit-content;
      padding: 0 1rem 0 0.5rem;
      text-align: start;
      flex-grow: 1;    

      &_img {
        padding: 0.8rem 0;
      }
    }    
  }

}

@media (max-width: 65em) {
  .header {
    top: -2em;
    clip-path: none;
    height: 5em;

      &-content {
        padding-top: 1rem;

        &--wave {
          display: none;
        }

        &--logo {
          padding-top: 0.8rem;
          flex-shrink: 1;

          &_img {
            max-width: 80%;
          }
        }
    }
  }
}