@import '../../styles/globals';
@import '../../styles/vars';

.pagesEvents {
  text-align: start;
  width: $maxWidth;
  margin-inline: auto;

  &-formations {
    &--list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      // justify-content: center;
      margin-bottom: 2rem;

      &_title {
        margin: 1rem 0;
        width: 100%;
        text-align: start;
        color: white;
      }
    }

    &--title {
      color: white;
      margin: 2.5rem 0 1rem;
    }
  }
}
