@import '../../../styles/vars';

.offers {
  max-width: $maxWidth;
  margin-inline: auto;
  text-align: start;
  margin-bottom: 3rem;

  &-categories,
  &-numbers {
    margin-top: 2rem;
    display: grid;
    align-items: baseline;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  &-numbers {
    &--legend {
      font-size: 0.85rem;
    }
  }

  &-formations {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
  }

  &-list {
    &--title {
      margin: 2rem 0 1rem;
    }
  }
}

@media (max-width: 45em) {
  .offers {
    &-categories,
    &-numbers {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &-numbers {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 40em) {
  .offers {
    &-formations {
      flex-direction: column;
    }
  }
}
