@import '../../../../styles/vars';

//
.dangerous-html {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: clamp(0.942rem, 1vw + 0.5rem, 1rem);
  line-height: clamp(1.4rem, 1vw + 0.7rem, 1.6rem);
  text-align: start;
  margin-bottom: 1rem;

  // Type
  & > p {
    width: 100%;
    margin: 0 0 0.5rem 0;
    line-height: 1.3rem;
    text-align: start;
    color: white;

    & > span {
      color: white;
    }
  }

  & > strong {
    font-weight: $bold;
  }

  & > em {
    font-style: italic;
  }

  & > del {
    text-decoration: line-through;
  }

  & > ins {
    text-decoration: underline;
  }

  // Elements
  & > blockquote {
    position: relative;
    padding: 2rem 3rem;
    margin: 1rem 0.5rem;
    color: white;
    background-color: rgb(240, 240, 240);
    width: fit-content;

    &::before {
      content: '«';
      font-size: 4em;
      margin-left: -2.5rem;
      margin-top: -1.5rem;
      transform: rotate(45deg);
    }

    &::after {
      content: '»';
      bottom: 0;
      right: 0.2rem;
      margin-top: 1rem;
      transform: rotate(45deg);
    }
  }

  & > blockquote::before,
  & > blockquote::after {
    line-height: 0.8;
    position: absolute;
    font-size: 4em;
    // display: inline-block;
    color: white;
    font-size: 4em;
    top: 0.2em;
  }

  & > ul,
  & > ol {
    margin: 0;
    color: white;

    & > li {
      color: white;
      & > span {
        color: white;
      }
    }
  }

  & > ul {
    padding-left: 0.5rem;
    color: white;

    & > li {
      text-align: start;
      // list-style-type: disc;
      margin: 0.5rem 1rem;
      color: white;

      & > span {
        color: white;
      }
    }
  }

  & > ol {
    width: 90%;
    padding-left: 1rem;

    & > li {
      list-style-type: decimal;
      margin: 0.5rem 1rem;

      & > span {
        color: white;
      }
    }
  }
}

// .white > li {
//   list-style-type: disc;
//   color: white;
// }

// .white > li > span {
//   color: white;
// }
