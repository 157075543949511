@import '../../styles/vars';

.about {
  overflow: hidden;

  &-img {
    z-index: 0;
    height: 700px;
    // margin-top: -200px;
    box-shadow: inset 0 -20rem 30rem #00000070;
    left: 0;
    right: 0;
    min-width: 100vw;
    width: 100%;
    clip-path: url(#header-btm-clip);
    background-image: url('../../../public/imgs/pages/large/formacoach-homepage-large.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &--quote {
      color: white;
      text-align: left;
      width: 20em;
      z-index: 2;
      position: absolute;
      top: 28rem;
      left: 2em;
      font-style: italic;

      &_text {
        & > h3 {
          line-height: 4rem;
        }
      }
    }
  }

  &-content {
    color: white;
    max-width: $maxWidth;
    margin-inline: auto;

    &--title {
      text-align: start;
      margin-top: 1rem;
    }

    &--top {
      text-align: start;
    }

    &--values_title {
      margin: 1rem 0 2rem;
    }

    &--bottom {
      margin-top: 2rem;
      text-align: start;

      &_fondatrice {
        display: flex;
        align-items: center;
        gap: 2rem;
        border: 2px solid white;
        padding: 1rem 1rem 1rem 1.5rem;
        max-width: 100%;
        margin-bottom: 2rem;

        &-img {
          height: 13em;
          aspect-ratio: 1;
          margin-bottom: 1.5rem;

          transform: rotate(40deg);
          & > img {
            border-radius: 50%;
          }
        }
      }

      &_details {
        display: grid;
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 2rem;

        &-team {
          grid-column: 1 / 2;

          &--img {
            overflow: hidden;
            max-width: fit-content;
          }
        }

        &-ambitions {
          grid-column: 2 / 3;
        }
      }
    }
  }
}

@media (max-width: 50em) {
  .about {
    &-img {
      &--quote {
        width: 90%;
        top: 22rem;
        left: 1em;

        & > p {
          margin: 0.2em 0;
        }
      }
    }

    &-content {
      margin-top: -3rem;

      &--top {
        &_title {
          margin-bottom: 1rem;
        }
      }

      &--bottom {
        &_fondatrice {
          flex-direction: column;
          border: none;
          padding: 0;
        }

        &_details {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .about {
    &-img {
      background-image: url('../../../public/imgs/pages/small/formacoach-homepage-small.jpg');
    }
  }
}
