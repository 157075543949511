@import '../../styles/globals';
@import '../../styles/vars';

.displayFormations {
  text-align: start;
  width: 100%;
  margin-inline: auto;

  &-subtitle {
    color: white;
    padding-top: 0.5rem;
  }

  &-cardslist {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
  }

  &-itemslist {
    padding: 1rem 0;

    &--title {
      color: white;
      margin: 1rem 0;
    }
  }
}

@media (max-width: 40em) {
  .displayFormations {
    &-cardslist {
      flex-direction: column;
    }

    &-itemslist {
      padding: 1rem 0;
    }
  }
}
