@import '../../styles/vars';

.spacer {
  color: transparent;
  font-size: 1rem;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 10rem;

  --animation: 10s linear infinite;

  --l1Start: 100%;
  --l1End: -100%;

  --l2Start: 0%;
  --l2End: -200%;

  --animation-params: 20s linear infinite;
  --animation-delay: 10s;

  height: 20em;
  margin-inline: auto;

  &-title {
    margin: 0 2rem 1rem;
  }

  &-list {
    max-width: 1920px;
    // margin: 0 2rem 2rem;
    position: absolute;
    left: calc(50% - (100vw / 2));
    right: 0;
    text-align: center;
    overflow: hidden;
    padding: 1rem 0;

    &:hover > &--item_line-value {
      color: $gradient;
    }

    &--item {
      position: relative;
      letter-spacing: 2rem;
      display: flex;
      left: 0;
      // font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      text-align: start;

      &_line {
        font-family: 'Montserrat', sans-serif;
        color: rgba(255, 255, 255, 0.2);
        font-weight: $black;
        white-space: nowrap;
        font-size: clamp(1.5rem, 3vw + 0.5rem, 4rem);
        line-height: 1.05;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        margin: 0;
        display: inline-block;

        &:hover > &-value {
          transition: 500ms ease-in-out;
          color: white;
        }
      }
    }
  }
}

.filled {
  color: white;
  font-family: 'Jost', sans-serif;
  font-weight: $black;
}

@media (max-width: 40em) {
  .values {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 30em;

    &-list {
      &--item {
        &_line {
          font-size: 2rem;
        }
      }
    }

    & ul,
    li {
      height: auto;
    }

    &-title {
    }

    & > ul {
    }

    &-list {
    }
  }
}

@media (max-width: 20em) {
  .values {
    &-list {
      transform: translateX(-250px);
    }
  }
}
