// @use 'reset';

@import './_vars.scss';

// Title fonts
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Texts font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// Values font => Jost fucked up on outlines of font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

@font-face {
  font-family: 'Coven';
  font-style: normal;
  font-weight: normal;
  src: local('Coven-Outlined'), url('../../public/fonts/Coven-Outlined.woff') format('woff');
}

html {
  scroll-behavior: smooth;
  scrollbar-color: $blueDark $blueLight;
  scrollbar-width: 10px;
  color: white;
}

// CUSTOM SCROLLBAR
html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: $blueLight;
  border-radius: 0.5rem;
}

html::-webkit-scrollbar-track {
  background-color: $blueDark;
}

body {
  margin: 0;
  font-family: 'Jost', 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Jost', sans-serif;
  margin: 0;
}

p {
  font-weight: $regular;
  margin: 0.5rem 0;
}

ol,
ul,
li {
  // list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/* "MENU" text for screen reeders Hide element visually but keeps it in the dom for screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}
