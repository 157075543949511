// COLORS
$blueDark: #25275e;
$pink: #951b81;
$blueLight: #1d70b7;
$gradient: linear-gradient(
  236deg,
  rgba(149, 27, 129, 1) 0%,
  rgba(37, 39, 94, 1) 33%,
  rgba(11, 4, 102, 1) 67%,
  rgba(29, 112, 183, 1) 100%
);

// LAYOUT
$maxWidth: min(90%, 1200px);

// TEXT
$xlight: 100;
$light: 200;
$thin: 300;
$regular: 400;
$sbold: 600;
$bold: 700;
$xbold: 800;
$black: 900;

$big: clamp(1.8rem, 4vw + 1rem, 4rem);
$medium: clamp(1rem, 3vw + 0.5rem, 2rem);
