@import '../../styles/vars';

.nav {
  // width: 100%;

  &-closed {
    padding-bottom: 0.5rem;
  }
  
  &-btn {
    display: none;
  }
  
  &-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;

    & > ul {
      padding: 0
    }
    
    &--item {
      color: $blueDark;
      text-decoration: none;
      text-align: end;
      // text-transform: uppercase;
      letter-spacing: .02rem;
      font-size: 1rem;
      padding-top: 0.8rem;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Padding avoids element shifting when selected
.active {
  width: 100%;
  border-top: 3px solid $pink;

  & > ul {  
    & > li {
      height: 100%;
      text-align: end;
    }
}

}



@media (max-width: 65em) {
  .active {
    width: 90%;
    height: 100%;
    border-top: none;
  } 
  
  .nav {
    position: fixed;
    top: 0;
    background: $gradient;
    background-size: 150%;
    background-position: right;
    height: 100vh;
    width: 100%;
    padding: min(20vh, 5rem) 1em;
    transition: 200ms ease-out;
    
    &-open {
      inset: 0;
    }

    &-closed {
      left: 100%;
    }
    
    &-btn {
      display: block;
      position: fixed;
      top: 0.5em;
      right: 1.2em;
      width: 40px;
      height: 40px;
      // background-color: red;
      background: transparent;
      border: none;
      aspect-ratio: 1;
      
      &--open {
        width: 100%;
        height: 4px;
        background: $blueDark ;
        border-radius: 2px;
        position: absolute;
        left: 0;

        &::before, &::after {
          content: '';
          height: 4px;
          border-radius: 2px;
          background: $blueDark ;
          position: absolute;
          right: 0;
        }

        &::before {
          width: 70%;
        }

        &::after {
          width: 90%;

        }
        
        &::before {
          top: 12px;
        }
        &::after {
          bottom: 12px;
          opacity: 1;
        }
      }

      &--close {
        width: 100%;
        height: 4px;
        background: white;
        border-radius: 2px;
        position: absolute;
        left: 0;
        transform: rotate(405deg);
        transition: 500ms ease-in-out;

        &::before, &::after {
          content: '';
          width: 100%;
          height: 4px;
          border-radius: 2px;
          background: white;
          position: absolute;
          left: 0;
        }
        
        &::before {
          top: 12px;
          transform: rotate(450deg) translate(-10px);
        }
        &::after {
          bottom: 12px;
          opacity: 0;
        }
      }
    }
  
    &-list {
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
      width: 80%;

      & > ul, li {
        text-align: start;
      }
      
      &--item {
        color: white;
        text-decoration: none;
        font-size: clamp(1rem, 4vw + 0.5rem, 2rem);
        font-weight: $black;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 40em) and (orientation: landscape) {
  .nav {
    padding: 1rem 0 2rem;

    &-list {
      gap: 1rem;

      &--item {
        font-size: 1.5rem;
      }
    }
  }
}
