@import '../../styles/vars';

.ressources {
  max-width: $maxWidth;
  min-height: 70vh;
  margin-inline: auto;
  text-align: start;
  color: white;

  &-formateurs {
    &--list {
      display: flex;
      gap: 2em;

      &_left,
      &_right {
        flex: 1 1 0;

        &-title {
          margin: 0.2em 0 0.4em;
        }

        &-item {
          text-align: start;
          padding: 0.2em 0;
          margin-left: 1.5rem;
        }

        & > ul > li:last-child {
          margin-bottom: 1em;
          list-style-type: none;
        }
      }
    }
  }
}

@media (max-width: 40em) {
  .ressources {
    &-formateurs {
      &--list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    &-supervision {
      &--text {
        flex-direction: column;
        gap: 0;

        &_paragraph {
          width: 100%;
        }
      }
    }
  }
}
