@import '../../../../styles/vars';
@import '../../../../styles/globals';

.loading {
  z-index: 9999;
  position: absolute;
  top: 0;
  opacity: 0;
  height: 100vh;
  width: 100vw;
  margin-inline: auto;
  max-width: 100%;
  background-color: #05041a;
  overflow: hidden;

  &-wave {
    z-index: 90;

    width: 100%;
    height: 100%;

    &--path {
      position: relative;
      top: 0;
    }
  }

  &-text {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    letter-spacing: 0.2rem;
    color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_logo {
      margin-inline: auto;
      align-self: center;

      & > img {
        width: clamp(40%, 200px, 4em);
        position: relative;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
